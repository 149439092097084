import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AboutSection, Avatar, Title, AltTitle, Text, SubTitle } from "./style"
import { SectionIntro, ContainerLayout, ResumeButton } from "../common"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "robert_hardi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SectionIntro>
        <ContainerLayout>
          <AboutSection>
            <div>
              <Avatar
                fluid={data.placeholderImage.childImageSharp.fluid}
                alt="user photo"
              />
              <SubTitle> Robert Hardi, GASTROENTEROLOGIST</SubTitle>
            </div>
            <div>
              <Title> Robert Hardi, M.D., AGAF, CPI </Title>
              <AltTitle>
                {" "}
                Reliability, discretion and professionalism.{" "}
              </AltTitle>
              <Text>
                {" "}
                These are the values that define me in my work. I do my job by
                the latest medical standards in gastroenterology and provide
                high quality, comprehensive care for patients with a wide
                variety of gastrointestinal ailments. I combine my 40+ years of
                experience with modern science developments in order to create a
                complex and reliable treatment process for my patients. My
                priority is to provide my patients with the most effective
                treatment by leaving them in their comfort zones.
              </Text>
              <ResumeButton href="HardiRobertCV.pdf" target="_blank">
                {" "}
                Download resume{" "}
              </ResumeButton>
            </div>
          </AboutSection>
        </ContainerLayout>
      </SectionIntro>
    </>
  )
}

export default About
